
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  onIonViewDidEnter,
  IonBackButton,
  IonButtons,
  IonIcon,
  IonButton,
  IonText,

  actionSheetController,

} from '@ionic/vue';

import { ActionSheetButton } from "@ionic/core";

import { 
  checkmark,
  checkmarkCircle,
  ellipseOutline,
} from 'ionicons/icons';

import { defineComponent, ref, nextTick } from '@vue/runtime-core';

import  { Contacts, Contact, PermissionStatus } from '@capacitor-community/contacts';

import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import t from '@/translation';
//import { demoAndroidContacts } from '@/lib/demo-contacts';
import { Guest, isApiError } from '@/lib/api-endpoints';
import { guestStatuses } from '@/lib/guest-statuses';
import { loadingController } from '@ionic/vue';

export default  defineComponent({
  name: 'Guests',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonBackButton,
    IonButtons,
    IonIcon,
    IonButton,
    IonText,
  },
  setup() {

/*
    DEVICE CONTACTS INTERFACE.

    export interface PermissionStatus {
      granted: boolean;
    }

    export interface Contact {
      contactId: string;
      displayName?: string;
      phoneNumbers: PhoneNumber[];
      emails: EmailAddress[];
      photoThumbnail?: string;
      organizationName?: string;
      organizationRole?: string;
      birthday?: string;
    }

    export interface PhoneNumber {
      label?: string;
      number?: string;
    }

    export interface EmailAddress {
      label?: string;
      address?: string;
    }
*/

    const contacts = ref<readonly Contact[]>([]);
    const selectedIndices = ref<{contactIdx: number; emailIdx: number; phoneIdx: number}[]>([]);
    const contactError = ref("");
    const store = useStore();
    const router = useRouter();

    onIonViewDidEnter(() => {
      selectedIndices.value = [];
      contactError.value = "";
      Contacts.getPermissions().then((perms: PermissionStatus)=>{
        if (perms.granted){

          loadingController.create({
            cssClass: 'api-request-loading',
            message: t("Loading device contacts..."),
            duration: 10000,
          }).then((loading) => {
            loading.present().then(()=>{
              Contacts.getContacts().then((result: {contacts: Contact[]})=>{
                contacts.value = Object.freeze(result.contacts);
                nextTick(()=>loading.dismiss());
              }).catch((ex)=>{
                contactError.value = ex.message;
              });
            });
          });

          
        } else {
          contactError.value = t("You have denied permission to access your contacts.  If you want to import guests, go to your devices settings and grant permission to access your contact list.");
        }
      }).catch((ex)=>{
        if (ex.message == "getPermission not available"){
          //contacts.value = demoAndroidContacts.contacts;
          contactError.value = "Device contacts are not available in a web browser.";
        } else {
          contactError.value = ex.message;
        }
      });
    });

    function isSelected(contactIdx: number){

      return selectedIndices.value.findIndex(el => el.contactIdx == contactIdx) > -1;
    }

    async function selectEmailAddress(contactIdx: number, phoneIdx: number){
      const contact = contacts.value[contactIdx];
      if (contact.emails.length == 1){
        selectedIndices.value.push({contactIdx, phoneIdx, emailIdx:0});
      } else if (contact.emails.length < 1){
        selectedIndices.value.push({contactIdx, phoneIdx, emailIdx:-1});
      } else {
        // if the contact has more than one phone, prompt the user to select it.
        const emailButtons: ActionSheetButton[] = [ { text: 'Cancel', role: 'cancel' } ];
        for (const [idx,email] of contact.emails.entries()){
          emailButtons.push({
            text: email.label + ": " + email.address,
            handler: () => { selectedIndices.value.push({contactIdx, phoneIdx, emailIdx:idx}) }
          });
        }
        const emailActionSheet = await actionSheetController.create({
          header: 'Select Email',
          buttons: emailButtons,
        });
        await emailActionSheet.present();
      }
    }

    async function selectPhoneNumber(contactIdx: number){
      const contact = contacts.value[contactIdx];
      if (contact.phoneNumbers.length == 1){
        selectEmailAddress(contactIdx, 0);
      } else if (contact.phoneNumbers.length < 1){
        selectEmailAddress(contactIdx, -1);
      } else {
        // if the contact has more than one phone, prompt the user to select it.
        const phoneButtons: ActionSheetButton[] = [ { text: 'Cancel', role: 'cancel' } ];
        for (const [idx,phone] of contact.phoneNumbers.entries()){
          phoneButtons.push({
            text: phone.label + ": " + phone.number,
            handler: () => {selectEmailAddress(contactIdx, idx)}
          });
        }
        const phoneActionSheet = await actionSheetController.create({
          header: 'Select Phone',
          buttons: phoneButtons,
        });
        await phoneActionSheet.present();
      }
    }


    function itemClicked(contactIdx: number){
      const index = selectedIndices.value.findIndex(el => el.contactIdx == contactIdx);
      if (index !== -1) {
        selectedIndices.value.splice(index, 1);
      } else {
        selectPhoneNumber(contactIdx);
      }
    }

    async function onImportClicked(){
      for (const selectedContact of selectedIndices.value){
        const contact = contacts.value[selectedContact.contactIdx];
        let names;
        if (contact.displayName){
          // safari doesn't support this regex!
          // names = contact.displayName.split(/(?<=^\S+)\s/)
          names = [ 
            contact.displayName.substring(0, contact.displayName.lastIndexOf(" ") + 1), // up to the last space
            contact.displayName.substring(contact.displayName.lastIndexOf(" ") + 1, contact.displayName.length), // after the last space
          ];
        } else {
          names = ["", ""];
        }
        while (names.length < 2) names.push("");

        let email = "";
        let phone = "";

        if (selectedContact.emailIdx > -1){
          email = contact.emails[selectedContact.emailIdx].address!;
        }
        
        if (selectedContact.phoneIdx > -1){
          phone = contact.phoneNumbers[selectedContact.phoneIdx].number!;
        }


        /* eslint-disable @typescript-eslint/camelcase */
        const guest: Guest = {
          first_name: names[0],
          last_name: names[1],
          address_line_1: "",
          address_line_2: "",
          //city: "", 
          suburb: "",
          state: "",
          postcode: "",
          country: "",
          email: email,
          phone: phone,
          table: "",
          dietary: "",
          invited: guestStatuses.get('invited')!.statusId,
          special_request: "",
        }

        const result = await store.dispatch('saveGuest', { ... guest, wedding_id: store.getters.wedding.id });
        /* eslint-enable @typescript-eslint/camelcase */
        if (isApiError(result)){
          return;
        }

      }
      router.go(-1);
    }

    return {
      checkmark,
      checkmarkCircle,
      ellipseOutline,

      contacts,
      contactError,
      selectedIndices,

      isSelected,
      itemClicked,
      onImportClicked,
    };

  }
});
