import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = { class: "ion-text-center ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/tabs/guests/settings",
                    text: _ctx.$t('Cancel')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _withModifiers(_ctx.onImportClicked, ["prevent"]),
                    disabled: _ctx.selectedIndices.length == 0
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.checkmark
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Import')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Import Guests')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          (_ctx.contactError)
            ? (_openBlock(), _createBlock("div", _hoisted_1, [
                _createVNode("h4", _hoisted_2, [
                  _createVNode(_component_ion_text, { color: "danger" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Error retreiving your contacts!')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode("p", _hoisted_3, _toDisplayString(_ctx.contactError), 1)
              ]))
            : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.contacts, (contact, idx) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      button: "",
                      key: idx,
                      onClick: ($event: any) => (_ctx.itemClicked(idx)),
                      detail: "false",
                      lines: "full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: (_ctx.isSelected(idx)) ? _ctx.checkmarkCircle : _ctx.ellipseOutline,
                          color: _ctx.isSelected(idx) ? 'primary' : 'text',
                          slot: "start"
                        }, null, 8, ["icon", "color"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(contact.displayName), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}